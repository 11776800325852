import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styles from './css/digis.module.css'
import Chart from 'chart.js/auto';

import loading from './images/loading.gif';


//HTTPS
const socket = new WebSocket("wss://aprsbr.com.br:8089");

//HTTP
//const socket = new WebSocket("ws://localhost:8089/");

const Digis = (props) => {
  
  const { loggedIn, user } = props;
  
  //const user = localStorage.getItem("user");
  const [messages, setMessages] = useState([]);
  //const [messageInput, setMessageInput] = useState('');
  const [isOnline, setIsOnline] = useState(false);
  const [digiIP, setDigiIP] = useState('');
    
  const navigate = useNavigate();

  useEffect(() => {
    socket.onopen = () => {
      console.log("WebSocket Client Connected");
      setIsOnline(true); 
    };
    socket.onmessage = (event) => {
      const newMessages = [...messages, event.data];
      setMessages(newMessages);
    };

  }, [messages]);

  

  useEffect(() => {
    setTimeout(() => {
      if (socket.readyState === WebSocket.OPEN) {
        setIsOnline(true); 
      }
      if (socket.readyState === WebSocket.CLOSED) {
        setIsOnline(false);
      }
    }, 5);
  }, [user]);

  
  async function geraGrafico() {

    let chartStatus = Chart.getChart("graficoTemperatura"); // <canvas> id
    if (chartStatus !== undefined) {
      chartStatus.destroy();
    }

    setDigiIP(JSON.parse(messages[messages.length-1]).type)
    //console.log(messages.filter(name => JSON.parse(name)).map(f => (JSON.parse(f).date)))
    //console.log(messages.filter(name => JSON.parse(name)).map(f => (JSON.parse(f).text)))

    //console.log(messages.filter(name => JSON.parse(name).user.includes('PU2ONU-10')).map(f => (JSON.parse(f).date).split(" ")[1]))
    //console.log(messages.filter(name => JSON.parse(name).user.includes('PU2ONU-10')).map(f => (JSON.parse(f).text)))

    //console.log(messages.filter(name => JSON.parse(name).user === 'PU2ONU-10').map(f => (JSON.parse(f).date).split(" ")[1]))
    //console.log(messages.filter(name => JSON.parse(name).user === 'PU2ONU-10').map(f => (JSON.parse(f).text)))

  

    
    new Chart(
      document.getElementById('graficoTemperatura'),
      {
        type: 'line',
        data: {
          labels: messages.filter(name => JSON.parse(name).user === user.replaceAll('"','')).map(f => (JSON.parse(f).date).split(" ")[1]),


          //labels: messages.map((friend, index) => (JSON.parse(friend).date).split(" ")[1]),
          datasets: [
            {
              label: 'Temp',
              data: messages.filter(name => JSON.parse(name).user === user.replaceAll('"','')).map(f => (JSON.parse(f).text)),
              //data: messages.map((friend, index) => (JSON.parse(friend).text)),
              tension: 0.3,
              borderColor: "rgba(255,0,0,0.7)", 
              fill: true,
              backgroundColor: "rgba(255,0,0,0.1)"
            }
          ]
        },
        options: {
          plugins: {
            legend: {
              display: false //Sem a legenda acima do grafico
            }
          }
        }
      }
    );
  
  };

  useEffect(() => {
    if (messages.length>0 && JSON.parse(messages[messages.length-1]).user === user.replaceAll('"','')) {
        geraGrafico()
    }
  });

  function reload() {
    window.location.reload();
  }

  function goLogin() {
    navigate("/login");
  }
  /*
  function reload() {
    window.location.reload();
  }

  // onMessage
  useEffect(() => {
    socket.onmessage = (event) => {
      const newMessages = [...messages, event.data];
      setMessages(newMessages);
      
    };
  
  }, [messages]);

  // onClose
  useEffect(() => {
    return () => {
      socket.close();
    };
  }, []);

  // Gera grafico com os dados de temperatura da Digi
  useEffect(() => {
    if (messages.length>0 && JSON.parse(messages[messages.length-1]).user === user.replaceAll('"','')) {
        geraGrafico()
    }
  });

//setIsOnline
  useEffect(() => {
    setTimeout(() => {
      if (socket.readyState === WebSocket.OPEN) {
        setIsOnline(true); 
      }
      if (socket.readyState === WebSocket.CLOSED) {
        setIsOnline(false);
      }
    }, 5);
  }, [user]);
*/
  

//TODO se ambos fores false... colocar um div pra recarregar a pagina apenas.
//console.log('loggedIn:', loggedIn)
//console.log('isOnline:', isOnline)

   
    return (
      <center>
        {!loggedIn
        ?   <>
            <br /><br /><br /><br /><br /><br /><div className={styles.titleContainer}>Usuário não logado!</div>
            <div className={"buttonContainer"}>
            <input
                className={styles.inputButton}
                type="button"
                onClick={goLogin}
                value="Login" />
            
        </div>

            
            </>
        : <>
        <div className={styles.mainContainer}>
        <br /><br /><br /><br /><br />





        <div>
          {(!loggedIn || !isOnline)
          && <><br /><br /><div className={styles.disconnected}><i className="fa-solid fa-wifi"></i></div><button className={styles.btnReload} onClick={reload}>Nao conectado, Clique aqui para tentar reconectar!</button><br /><br /></>
          }
        
        {messages.length>0 && messages.toString().includes(user.replaceAll('"',''))
        && <><div className={styles.connected}><i className="fa-solid fa-wifi"></i>&nbsp;&nbsp;Online: {digiIP}</div><br /></>
        }

        </div>
        <div className={styles.grafico}>Grafico de temperatura da DIGI <b>{user.replaceAll('"','')}</b></div>
        <div className={styles.space}></div>

        {messages.length>0 && messages.toString().includes(user.replaceAll('"',''))
          ? <div className={styles.grafico}><canvas id="graficoTemperatura"></canvas></div>
          : <div className={styles.graficoAguardando}>Aguardando dados da DIGI <b>{user.replaceAll('"','')}</b>...<br/><br/><img style={{width: "50px"}} src={loading} alt="loading" /></div>
        }
        
        <br />
       
        <div className={styles.containerMessages}>
          
          <div><div className={styles.grafico}>Últimos 10 envios - <b>IP {digiIP}</b></div></div>
          <div className={styles.space}></div>
          
            {messages.filter(name => JSON.parse(name).user.split("-")[0] === user.replaceAll('"',"").split("-")[0]).slice().reverse().map((message, index) => index < 10 && 
              (JSON.parse(message).select === "digis" && JSON.parse(message).user.split("-")[0] === user.replaceAll('"',"").split("-")[0])
              && (
                
                <div key={index}>
                  <div className={styles.message}><i className="fa-regular fa-calendar-days"></i>&nbsp;{JSON.parse(message).date}&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa-solid fa-walkie-talkie"></i>&nbsp;<b><i>{JSON.parse(message).user}</i></b>&nbsp;&nbsp;&nbsp;&nbsp;{JSON.parse(message).text.toFixed(1)}&nbsp;<i className="fa-solid fa-temperature-low"></i>
                  </div>
                </div>
              )
              
            )}
            
          </div>
            

        </div>
        </>
        
}
        </center>
      );
    };

export default Digis



