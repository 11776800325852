/* eslint-disable no-undef */
import React from "react"
import styles from './css/tracker.module.css'

const Tracker = (props) => {
  
    var port, textEncoder, writer;

    async function connectSerial() {
        
    port = await navigator.serial.requestPort();

    if (!port) {
      alert("Failed to find a serial port");
    } else {
      console.log("connected");
    }
    
    //await port.open({baudRate: 115200, flowControl: "hardware", bufferSize: 8192});
    await port.open({baudRate: 115200});

    textEncoder = new TextEncoderStream();
    const writableStreamClosed = textEncoder.readable.pipeTo(port.writable);
    console.log(writableStreamClosed)
    

    writer = textEncoder.writable.getWriter();
    listenToPort();
    appendToTerminal("Conectado...Aguardando dados...<br />");
    }
    
    async function sendSerialLine(_id) {
      //document.getElementById("lineToSend").value = document.getElementById("lineToSend").value;

      if (_id === "config") {
        appendToTerminal("<br /><font color='yellow'>> CONFIG<br /></font>");
        await writer.write("CONFIG");  
      } else {

        let obj = document.getElementById(_id);
        let q = _id.toUpperCase() + "=" + obj.value;
        //console.log(obj.value);
        appendToTerminal("<br /><font color='yellow'>> " + q + "<br /></font>");
        await writer.write(q);
      }

    }

    class LineBreakTransformer {
      constructor() {
        // A container for holding stream data until a new line.
        this.chunks = "";
      }
    
      transform(chunk, controller) {
        // Append new chunks to existing chunks.
        this.chunks += chunk;
        // For each line breaks in chunks, send the parsed lines out.
        const lines = this.chunks.split("\r\n");
        this.chunks = lines.pop();
        lines.forEach((line) => controller.enqueue(line));
      }
    
      flush(controller) {
        // When the stream is closed, flush any remaining chunks out.
        controller.enqueue(this.chunks);
      }
    }

    
    async function disconnect() {
      clearTerminal();
      window.location.reload();
    }
    
    async function listenToPort() {
      const textDecoder = new TextDecoderStream();
      const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
      console.log(readableStreamClosed)      
      //const reader = textDecoder.readable.getReader();
      const reader = textDecoder.readable.pipeThrough(new TransformStream(new LineBreakTransformer())).getReader();

      while (true) {
          const { value, done } = await reader.read();
          if (done) {
              reader.releaseLock();
              break;
          }
          appendToTerminal(value+"<br />");
          
      }
    }
  
    async function appendToTerminal(newStuff) {
      document.getElementById("serialResults").innerHTML = newStuff + document.getElementById("serialResults").innerHTML;
      if (document.getElementById("serialResults").innerHTML.length > 3000) document.getElementById("serialResults").innerHTML = document.getElementById("serialResults").innerHTML.slice(document.getElementById("serialResults").innerHTML.length - 3000);
  
      //scroll down to bottom of div
      document.getElementById("serialResults").scrollTop = document.getElementById("serialResults").scrollHeight;
    }

    function clearTerminal() {
      document.getElementById("serialResults").innerHTML = "";
    }
      
    return (
        <>
        <br /><br /><br /><br /><br />
        <center>

       {/*
        Baudrate:
        <input type="text" id="baud" list="baudList" />
       
        <datalist id="baudList">
            <option value="300">300</option>
            <option value="600">600</option>
            <option value="1200">1200</option>
            <option value="2400">2400</option>
            <option value="4800">4800</option>
            <option value="9600">9600</option>
            <option value="14400">14400</option>
            <option value="19200">19200</option>
            <option value="38400">38400</option>
            <option value="57600">57600</option>
            <option value="115200">115200</option>
            <option value="128000">128000</option>
            <option value="256000">256000</option>
        </datalist>
        */}
      
        <button className={styles.btnConnect} onClick={connectSerial}>Conectar</button>
        &nbsp;&nbsp;<button className={styles.btnDisconnect} onClick={disconnect}>Desconectar</button>

        <br /><br />
        
        <input className={styles.inputMessage} type="text" id="call" placeholder="INDICATIVO SEM SSID EX: PU2XXX" />
        &nbsp;&nbsp;<button className={styles.btnSend} onClick={() => {sendSerialLine("call")}}>Enviar</button>
        
        <br /><br />
        <input className={styles.inputMessage} type="text" id="ssid" placeholder="SSID EX: 1" />
        &nbsp;&nbsp;
        <button className={styles.btnSend} onClick={() => {sendSerialLine("ssid")}}>Enviar</button>

        <br /><br />
        <input className={styles.inputMessage} type="text" id="graus" placeholder="SMARTBEACON TX CURVA EM GRAUS EX: 14" />
        &nbsp;&nbsp;
        <button className={styles.btnSend} onClick={() => {sendSerialLine("graus")}}>Enviar</button>
        
        <br /><br />
        <input className={styles.inputMessage} type="text" id="txi" placeholder="SMARTBEACON TX QUANDO PARADO EM (ms) EX: 30000 = 30s" />
        &nbsp;&nbsp;
        <button className={styles.btnSend} onClick={() => {sendSerialLine("txi")}}>Enviar</button>

        <br /><br />
        <button className={styles.btnSend} onClick={() => {sendSerialLine("config")}}>LER CONFIG DO TRACKER</button>

        <br /><br />
        <pre><div className={styles.mainContainer} id="serialResults"></div></pre>


</center>
</>
    )
}

export default Tracker