/* eslint-disable no-undef */
import React from "react"
import { useEffect } from 'react'

import styles from './css/aprstt.module.css'

// Use the DTMFgenerator object here
const Aprstt = (props) => {

    var contextClass = (window.AudioContext ||
    window.webkitAudioContext ||
    window.mozAudioContext ||
    window.oAudioContext ||
    window.msAudioContext);

    var oscillator1, oscillator2, gainNode;

    //const searchParams = new URLSearchParams(window.location.search);

    //let la = searchParams.get("lat");
    //let lo = searchParams.get("long");
    //let nObj = searchParams.get("numObj");
    //let _call = searchParams.get("call");
    //let user = props.user;
    let t_tone = 200;
    let t_stop = 100;


    async function showPosition(position) {  
        document.getElementById("codigoDTMF").value = (position.coords.latitude*-1).toString().replace(".","").substring(0,6) + 
        (position.coords.longitude*-1).toString().replace(".","").substring(0,6) +
        "7826681594123146520#"
    }

    function getLocation() {
        console.log("pegando geolocation...")
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
    } else {
        document.getElementById("codigoDTMF").value = "Geolocation is not supported by this browser.";
    }
    }

    useEffect(() => {
    getLocation();
    });


    if (contextClass) {
    // Web Audio API is available.
    var context = new contextClass();
    }


    var dialTone = function(freq1, freq2) {

    oscillator1 = context.createOscillator();
    oscillator1.frequency.value = freq1;
    gainNode = context.createGain ? context.createGain() : context.createGainNode();
    oscillator1.connect(gainNode,0,0);
    gainNode.connect(context.destination);
    gainNode.gain.value = .1;
    oscillator1.start ? oscillator1.start(0) : oscillator1.noteOn(0)

    oscillator2 = context.createOscillator();
    oscillator2.frequency.value = freq2;
    gainNode = context.createGain ? context.createGain() : context.createGainNode();
    oscillator2.connect(gainNode);
    gainNode.connect(context.destination);

    gainNode.gain.value = .1;
    oscillator2.start ? oscillator2.start(0) : oscillator2.noteOn(0)
    };

    function stop() {
    oscillator1.disconnect();
    oscillator2.disconnect();
    }

    /*
    function start() {
        if (typeof oscillator1 != 'undefined') oscillator1.disconnect();
        if (typeof oscillator2 != 'undefined') oscillator2.disconnect();
        oscOn(parseFloat(document.getElementById("freq").value),parseFloat(document.getElementById("freq2").value));
    }

    function maidenheadIndexLetter(idx) {
        return "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(idx);
    }

    async function latLngToMaidenheadIndex(lat, lng, precision) {

        lat = parseFloat(lat)
        lng = parseFloat(lng)

        let str = "";
        // lat-lng as percentages of the current scope
        let latPct = (lat + 90) / 180;
        let lngPct = (lng + 180) / 360;
        // lat-lng will become multiples of the current scope

        // Fields, 18x18 in total, each 20deg lng and 10deg lat
        lngPct *= 18;
        latPct *= 18;

        str += maidenheadIndexLetter(Math.floor(lngPct));
        str += maidenheadIndexLetter(Math.floor(latPct));
        if (precision === 2) {
            return str;
        }

        // Squares, 10x10 per field, each 2deg lng and 1deg lat
        lngPct = (lngPct - Math.floor(lngPct)) * 10;
        latPct = (latPct - Math.floor(latPct)) * 10;

        str += Number(Math.floor(lngPct));
        str += Number(Math.floor(latPct));
        if (precision === 4) {
            return str;
        }

        // Subsquares, 24x24 per square, each 5min lng and 2.5min lat
        lngPct = (lngPct - Math.floor(lngPct)) * 24;
        latPct = (latPct - Math.floor(latPct)) * 24;

        str += maidenheadIndexLetter(Math.floor(lngPct)).toLowerCase();
        str += maidenheadIndexLetter(Math.floor(latPct)).toLowerCase();
        if (precision === 6) {
            return str;
        }

        // Extended subsquares, 10x10 per subsquare, each 0.5min lng and 0.25min lat
        lngPct = (lngPct - Math.floor(lngPct)) * 10;
        latPct = (latPct - Math.floor(latPct)) * 10;

        str += Number(Math.floor(lngPct));
        str += Number(Math.floor(latPct));
        if (precision === 8) {
            //console.log('chegou aqui');
            console.log(str);
            document.getElementById("grid").innerHTML = "Grid Locator Model: " + str;
            str = str.toUpperCase()
            str = str.replaceAll('A','21')
            str = str.replaceAll('B','22')
            str = str.replaceAll('C','23')
            str = str.replaceAll('D','31')
            str = str.replaceAll('E','32')
            str = str.replaceAll('F','33')
            str = str.replaceAll('G','41')
            str = str.replaceAll('H','42')
            str = str.replaceAll('I','43')
            str = str.replaceAll('J','51')
            str = str.replaceAll('K','52')
            str = str.replaceAll('L','53')
            str = str.replaceAll('M','61')
            str = str.replaceAll('N','62')
            str = str.replaceAll('O','63')
            str = str.replaceAll('P','71')
            str = str.replaceAll('Q','72')
            str = str.replaceAll('R','73')
            str = str.replaceAll('S','74')
            str = str.replaceAll('T','81')
            str = str.replaceAll('U','82')
            str = str.replaceAll('V','83')
            str = str.replaceAll('W','91')
            str = str.replaceAll('X','92')
            str = str.replaceAll('Y','93')
            str = str.replaceAll('Z','94')
            document.getElementById("codigoDTMF").value = str + nObj + "#";
            await encodeDTMF2Digit(_call);
            await encodeDTMF2Key(_call);

            return str;
        }

        throw new Error(
            "Precision level passed to maidenhead latLngToIndex is invalid (must be 2, 4 6 or 8)"
        );
    }
    

    async function encodeDTMF2Digit(str) {
        let _lalo  = "";
        _lalo = la.toString().substr(0,8) + lo.toString().substr(0,8);
    
    // document.getElementById("latLong").innerHTML = "Lat Long Call Model: " + la + " " + lo + " " + _call;
        str = str.toUpperCase()
        str = str.replaceAll('A','2A')
        str = str.replaceAll('B','2B')
        str = str.replaceAll('C','2C')
        str = str.replaceAll('D','3A')
        str = str.replaceAll('E','3B')
        str = str.replaceAll('F','3C')
        str = str.replaceAll('G','4A')
        str = str.replaceAll('H','4C')
        str = str.replaceAll('I','4B')
        str = str.replaceAll('J','5A')
        str = str.replaceAll('K','5B')
        str = str.replaceAll('L','5C')
        str = str.replaceAll('M','6A')
        str = str.replaceAll('N','6B')
        str = str.replaceAll('O','6C')
        str = str.replaceAll('P','7A')
        str = str.replaceAll('Q','7B')
        str = str.replaceAll('R','7C')
        str = str.replaceAll('S','7B')
        str = str.replaceAll('T','8A')
        str = str.replaceAll('U','8B')
        str = str.replaceAll('V','8C')
        str = str.replaceAll('W','9A')
        str = str.replaceAll('X','9B')
        str = str.replaceAll('Y','9C')
        str = str.replaceAll('Z','9D')

        str = str.replaceAll('Z','9D')

        _lalo = _lalo.replaceAll("-","");
        _lalo = _lalo.replaceAll(".","");
        document.getElementById("latlong2d").innerHTML = "Lat Lon Call (2-Digit) Model";
        document.getElementById("codigoLatLong2d").value = _lalo + str + "#";
        
    }

    async function encodeDTMF2Key(str) {
        let _lalo  = "";
        let _base10 = _call;
        _lalo = la.toString().substr(0,8) + lo.toString().substr(0,8);
    console.log('0',_base10)
    // document.getElementById("latLong").innerHTML = "Lat Long Call Model: " + la + " " + lo + " " + _call;
        str = str.toUpperCase()
        str = str.replaceAll('A','2')
        str = str.replaceAll('B','2')
        str = str.replaceAll('C','2')
        str = str.replaceAll('D','3')
        str = str.replaceAll('E','3')
        str = str.replaceAll('F','3')
        str = str.replaceAll('G','4')
        str = str.replaceAll('H','4')
        str = str.replaceAll('I','4')
        str = str.replaceAll('J','5')
        str = str.replaceAll('K','5')
        str = str.replaceAll('L','5')
        str = str.replaceAll('M','6')
        str = str.replaceAll('N','6')
        str = str.replaceAll('O','6')
        str = str.replaceAll('P','7')
        str = str.replaceAll('Q','7')
        str = str.replaceAll('R','7')
        str = str.replaceAll('S','7')
        str = str.replaceAll('T','8')
        str = str.replaceAll('U','8')
        str = str.replaceAll('V','8')
        str = str.replaceAll('W','9')
        str = str.replaceAll('X','9')
        str = str.replaceAll('Y','9')
        str = str.replaceAll('Z','9')

        _base10 = _base10.toUpperCase()

        _base10 = _base10.replaceAll('0','0')
        _base10 = _base10.replaceAll('1','0')
        _base10 = _base10.replaceAll('2','0')
        _base10 = _base10.replaceAll('3','0')
        _base10 = _base10.replaceAll('4','0')
        _base10 = _base10.replaceAll('5','0')
        _base10 = _base10.replaceAll('6','0')
        _base10 = _base10.replaceAll('7','0')
        _base10 = _base10.replaceAll('8','0')
        _base10 = _base10.replaceAll('9','0')

        _base10 = _base10.replaceAll('Q','1')
        _base10 = _base10.replaceAll('Z','2')

        _base10 = _base10.replaceAll('A','1')
        _base10 = _base10.replaceAll('B','2')
        _base10 = _base10.replaceAll('C','3')
        _base10 = _base10.replaceAll('D','1')
        _base10 = _base10.replaceAll('E','2')
        _base10 = _base10.replaceAll('F','3')
        _base10 = _base10.replaceAll('G','1')
        _base10 = _base10.replaceAll('H','2')
        _base10 = _base10.replaceAll('I','3')
        _base10 = _base10.replaceAll('J','1')
        _base10 = _base10.replaceAll('K','2')
        _base10 = _base10.replaceAll('L','3')
        _base10 = _base10.replaceAll('M','1')
        _base10 = _base10.replaceAll('N','2')
        _base10 = _base10.replaceAll('O','3')
        _base10 = _base10.replaceAll('P','1')
        _base10 = _base10.replaceAll('R','2')
        _base10 = _base10.replaceAll('S','3')
        _base10 = _base10.replaceAll('T','1')
        _base10 = _base10.replaceAll('U','2')
        _base10 = _base10.replaceAll('V','3')
        _base10 = _base10.replaceAll('W','1')
        _base10 = _base10.replaceAll('X','2')
        _base10 = _base10.replaceAll('Y','3')
        
        _lalo = _lalo.replaceAll("-","");
        _lalo = _lalo.replaceAll(".","");

        _base10 = await convertFromBaseToBase(_base10.toString(),4,10);
        document.getElementById("latlong2k").innerHTML = "Lat Lon Call (2-Key) Model";
        document.getElementById("codigoLatLong2k").value = _lalo + str + _base10 + "#";
        
    }
    
    async function convertFromBaseToBase(str, fromBase, toBase){
        var num = parseInt(str, fromBase);
        return num.toString(toBase);
    }
    */

    function wait(milliseconds){
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
    }

    async function dtmfPlay(t){
    
        let s = "";
        if (t === "gridModel") {
            s = document.getElementById("codigoDTMF").value
        } else if (t === "latLongModel2d") {
            s = document.getElementById("codigoLatLong2d").value
        } else if (t === "latLongModel2k") {
            s = document.getElementById("codigoLatLong2k").value
        }
        
    
        console.log(s)
        document.getElementById("consol").innerHTML=""
        document.getElementById("consol").style.visibility = "visible"
    
    for (var i = 0; i < s.length; i++) {

        document.getElementById("consol").innerHTML+=s.charAt(i)
    
        if (s.charAt(i) === "1") {
            dialTone(697.0, 1209.0);
            await wait(t_tone);
            stop();
            await wait(t_stop);
        } else if (s.charAt(i) === "2") {
            
            dialTone(697.0, 1336.0);
            await wait(t_tone);
            stop();
            await wait(t_stop);
        } else if (s.charAt(i) === "3") {
            
            dialTone(697.0, 1477.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        } else if (s.charAt(i) === "A") {
            
            dialTone(697.0, 1633.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        }


        else if (s.charAt(i) === "4") {
            
            dialTone(770.0, 1209.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        } else if (s.charAt(i) === "5") {
            
            dialTone(770.0, 1336.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        } else if (s.charAt(i) === "6") {
            
            dialTone(770.0, 1477.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        } else if (s.charAt(i) === "B") {
            
            dialTone(770.0, 1633.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        }

        else if (s.charAt(i) === "7") {
            
            dialTone(852.0, 1209.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        } else if (s.charAt(i) === "8") {
            
            dialTone(852.0, 1336.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        } else if (s.charAt(i) === "9") {
            
            dialTone(852.0, 1477.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        } else if (s.charAt(i) === "C") {
            
            dialTone(852.0, 1633.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        }

        else if (s.charAt(i) === "*") {
            
            dialTone(941.0, 1209.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        } else if (s.charAt(i) === "0") {
            
            dialTone(941.0, 1336.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        } else if (s.charAt(i) === "#") {
            
            dialTone(941.0, 1477.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        } else if (s.charAt(i) === "D") {
            
            dialTone(941.0, 1633.0)
            await wait(t_tone);
            stop();
            await wait(t_stop);

        }




        
    }
    
    };

    return (

        <>
        <center><br /><br /><br /> <br />
        <div className={styles.title}>APRSTT (Touch Tone)</div>
        <div>
            <span type="text" id="grid" disabled style={{border: "0px", backgroundColor: "white"}}></span>
            <br />
            <span style={{backgroundColor: "transparent", border: "none"}}>Lat(6) + Lon(6) + Indicativo(2Dig-10) + N.Objeto(3) + Freq.(6)</span>
            <br /><br />
            <input className={styles.spanInput} alt="codigo" type="text" id="codigoDTMF" size="18" placeholder="Processando sua geolocalizacao..." />&nbsp;&nbsp;<span className={styles.spanButton} onMouseDown= {() => {dtmfPlay('gridModel')}}>PLAY</span>
            <br />                                                     
        </div>
        
        <br />
        
        <span className={styles.consol} id="consol"></span>
        
        <br /><br />
        
        <table className={styles.tableDial}>
            <tbody>
                    
            <tr>
                <td><button id="one" className={styles.beginDial} onMouseDown= {() => {dialTone(697.0, 1209.0)}} onMouseUp= {() => {stop()}} data-freq="697.0, 1209.0">1<br /></button></td>
                <td><button id="two" className={styles.beginDial}  onMouseDown= {() => {dialTone(697.0, 1336.0)}} onMouseUp= {() => {stop()}} data-freq="697.0, 1336.0">2<br />ABC</button></td>
                <td><button id="three" className={styles.beginDial}  onMouseDown= {() => {dialTone(697.0, 1477.0)}} onMouseUp= {() => {stop()}} data-freq="697.0, 1477.0">3<br />DEF</button></td>
                <td><button id="dtmf-a" className={styles.beginDial}  onMouseDown= {() => {dialTone(697.0, 1633.0)}} onMouseUp= {() => {stop()}} data-freq="697.0, 1633.0">A</button></td>
            </tr>

            <tr>
                <td><button id="four" className={styles.beginDial}  onMouseDown= {() => {dialTone(770.0, 1209.0)}} onMouseUp= {() => {stop()}} data-freq="770.0, 1209.0">4<br />GHI</button></td>
                <td><button id="five" className={styles.beginDial}  onMouseDown= {() => {dialTone(770.0, 1336.0)}} onMouseUp= {() => {stop()}} data-freq="770.0, 1336.0">5<br />JKL</button></td>
                <td><button id="six" className={styles.beginDial}  onMouseDown= {() => {dialTone(770.0, 1477.0)}} onMouseUp= {() => {stop()}} data-freq="770.0, 1477.0">6<br />MNO</button></td>
                <td><button id="dtmf-b" className={styles.beginDial}  onMouseDown= {() => {dialTone(770.0, 1633.0)}} onMouseUp= {() => {stop()}} data-freq="770.0, 1633.0">B</button></td>
            </tr>

            <tr>
                <td><button id="seven" className={styles.beginDial}  onMouseDown= {() => {dialTone(852.0, 1209.0)}} onMouseUp= {() => {stop()}} data-freq="852.0, 1209.0">7<br />PQRS</button></td>
                <td><button id="eight" className={styles.beginDial}  onMouseDown= {() => {dialTone(852.0, 1336.0)}} onMouseUp= {() => {stop()}} data-freq="852.0, 1336.0">8<br />TUV</button></td>
                <td><button id="nine" className={styles.beginDial}  onMouseDown= {() => {dialTone(852.0, 1477.0)}} onMouseUp= {() => {stop()}} data-freq="852.0, 1477.0">9<br />XWYZ</button></td>
                <td><button id="dtmf-c" className={styles.beginDial}  onMouseDown= {() => {dialTone(852.0, 1633.0)}} onMouseUp= {() => {stop()}} data-freq="852.0, 1633.0">C</button></td>
            </tr>

            <tr>
                <td><button className={styles.beginDial}  onMouseDown= {() => {dialTone(941.0, 1209.0)}} onMouseUp= {() => {stop()}} data-freq="941.0, 1209.0">*</button></td>
                <td><button id="zero" className={styles.beginDial}  onMouseDown= {() => {dialTone(941.0, 1336.0)}} onMouseUp= {() => {stop()}} data-freq="941.0, 1336.0">0<br />Space</button></td>
                <td><button className={styles.beginDial}  onMouseDown= {() => {dialTone(941.0, 1477.0)}} onMouseUp= {() => {stop()}} data-freq="941.0, 1477.0">#</button></td>
                <td><button id="dtmf-d" className={styles.beginDial}  onMouseDown= {() => {dialTone(941.0, 1633.0)}} onMouseUp= {() => {stop()}} data-freq="941.0, 1633.0">D</button></td>
            </tr>
            </tbody>
        </table>
        
        </center>
        </>
       
    )
}

export default Aprstt