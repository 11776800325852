import React, { useState } from 'react'; 
import styles from './css/realtime.module.css' 


//API TRACKER ISS
//https://api.wheretheiss.at/v1/satellites/25544
const Realtime = (props) => { 

  const [messages, setMessages] = useState([]); 
  const [connected, setConnected] = useState(false); 
  const [socket, setSocket] = useState(null);
  const [m, setM] = useState(0);
  const [l, setL] = useState(0);
  const [seeM, setSeeM] = useState(false);
  const [seeL, setSeeL] = useState(false);
  const [seeA, setSeeA] = useState(true);
  const [dataSource, setDataSource] = useState('');
  

  const connect = () => {
    
    //const socket = new WebSocket("wss://aprsbr.com.br:8088");
    document.getElementById("status").innerHTML = "Conectando ao servidor..."
    let socket;
    
    if (document.getElementById("select").value === "aprs") {
      //MSG APRS NORMAL
      socket = new WebSocket("wss://aprsbr.com.br:8088");
      //socket = new WebSocket("ws://localhost:8088");
      setDataSource("APRS");
      
    } else if (document.getElementById("select").value === "iss") {
      //MSG APRS ISS
      socket = new WebSocket("wss://aprsbr.com.br:8087");
      //socket = new WebSocket("ws://localhost:8087");
      setDataSource("ISS");
      
    } else {
      document.getElementById("status").innerHTML = "Nao Conectado! Conecte para ver pacotes de localizacao e mensagens em tempo real."
      alert("Escolha uma das fontes: APRS ou ISS\nE depois clique em CONECTAR");
      return;
    }

    socket.onopen = () => {
      console.log("WebSocket Client Connected");
      
      setConnected(true);
      document.getElementById("status").innerHTML = "Conectado! Aguardando mensagens..."
      document.getElementById("connect").style.display = 'none';
      document.getElementById("select").style.display = 'none';
      document.getElementById("disconnect").style.display = 'inline-block';
      document.getElementById("seeMessages").style.display = 'inline-block';
      document.getElementById("seeLocations").style.display = 'inline-block';
      document.getElementById("seeAll").style.display = 'inline-block';
    };
  
    socket.onmessage = (event) => {
      //setMessages((prevMessages) => [...prevMessages, event.data].reverse());
      setMessages((prevMessages) => [...prevMessages, event.data]);
      
      if (JSON.parse(event.data).type === "message") {
        setM((prevState) => (prevState + 1));
      } else if (JSON.parse(event.data).type === "location") {
        setL((prevState) => (prevState + 1));
      }
    };
  
    socket.onclose = () => {
      console.log("WebSocket Client Disconnected");
      setConnected(false);
    };
  
    socket.onerror = (error) => {
      console.log("WebSocket Error:", error);
      setConnected(false);
    };
  
    setSocket(socket);
  };

  const disconnect = () => {
    if (socket) {
      socket.close();
      setConnected(false);
      document.getElementById("output").innerHTML = "";
      document.getElementById("connect").style.display = 'inline-block';
      document.getElementById("select").style.display = 'inline-block';
      document.getElementById("disconnect").style.display = 'none';
      document.getElementById("seeMessages").style.display = 'none';
      document.getElementById("seeLocations").style.display = 'none';
      document.getElementById("seeAll").style.display = 'none';
      setM(0);
      setL(0);
      setSeeM(false);
      setSeeL(false);
      setSeeA(true);
      window.location.reload();
    }
  };

  function seeLocations() {
    setSeeM(false);
    setSeeL(true);
    setSeeA(false);
    const cardMessages = document.querySelectorAll('div#cardMessage')
    cardMessages.forEach((cardMessage) => {
      cardMessage.style.display = 'none';
    })
    const cardLocations = document.querySelectorAll('div#cardLocation')
      cardLocations.forEach((cardLocation) => {
    cardLocation.style.display = "inline-block";
    })
  }

  function seeMessages() {
    setSeeM(true);
    setSeeL(false);
    setSeeA(false);
    const cardMessages = document.querySelectorAll('div#cardMessage')
    cardMessages.forEach((cardMessage) => {
      cardMessage.style.display = 'inline-block';
    })
    const cardLocations = document.querySelectorAll('div#cardLocation')
      cardLocations.forEach((cardLocation) => {
    cardLocation.style.display = "none";
    })
  }

  function seeAll() {
    setSeeM(false);
    setSeeL(false);
    setSeeA(true);
    const cardMessages = document.querySelectorAll('div#cardMessage')
    cardMessages.forEach((cardMessage) => {
      cardMessage.style.display = 'inline-block';
    })
    const cardLocations = document.querySelectorAll('div#cardLocation')
      cardLocations.forEach((cardLocation) => {
    cardLocation.style.display = "inline-block";
    })
  }

  const messageList = messages.slice().reverse().map((message, index) => (
  //const messageList = messages.map((message, index) => (
    //console.log(message) && 
    JSON.parse(message).type === "message" 
    ?
    (seeM || seeA) &&
      <div key={index} className={styles.cardMessage} id="cardMessage">
        <div className={styles.cardimageMessage}><i className="fa-regular fa-envelope"></i></div>
        <div className={styles.cardImageBGMessage}></div>      
        <br /><span className={styles.date}>{JSON.parse(message).date} <i className="fa-solid fa-check-double"></i></span>
        <br /><span className={styles.depara}>DE: <b>{JSON.parse(message).de}</b></span>
        <br /><span className={styles.depara}>PARA: <b>{JSON.parse(message).para}</b></span>
        <br /><br /><br /><b>MENSAGEM</b><br />
        <br /><font className={styles.text}>{JSON.parse(message).text}</font>
      </div>
    
      :
      (seeL || seeA) &&
      <div key={index} className={styles.cardLocation} id="cardLocation">
        <div className={styles.cardImageBGLocation}></div>  
        <div className={styles.cardimageLocation}><i className="fa-solid fa-location-crosshairs"></i></div>
        <br /><span className={styles.date}>{JSON.parse(message).date} <i className="fa-solid fa-check-double"></i></span>
        <br /><span className={styles.depara}>DE: <b>{JSON.parse(message).de}</b></span>
        <br /><span className={styles.depara}>PARA: <b>{JSON.parse(message).para}</b></span>
        <br /><span className={styles.country}><i class="fa-solid fa-earth-americas"></i> {JSON.parse(message).country}</span>

        
        <br /><b>LOCALIZAÇÃO</b><br />
        <font className={styles.text}><i className="fa-solid fa-location-dot"></i> <b>Lat:</b> {JSON.parse(message).latitude} <b>Lon:</b> {JSON.parse(message).longitude}</font>
        <br /><br /><font className={styles.text}>
          
          {JSON.parse(message).speed >= 80 && <font color="#ff0000"><i className="fa-solid fa-gauge-high"></i></font> }
          {JSON.parse(message).speed > 0 && JSON.parse(message).speed < 80 && <font color="##00ca00"><i className="fa-solid fa-gauge-high"></i></font> }
          {JSON.parse(message).speed === 0 && <font><i className="fa-solid fa-gauge-high"></i> </font> }
          &nbsp;{JSON.parse(message).speed} km/h &nbsp;
          <i className="fa-solid fa-diamond-turn-right"></i> {JSON.parse(message).course}° <i className="fa-solid fa-mountain-sun"></i> {JSON.parse(message).altitude}m </font>
        <br /><br /><font className={styles.text}><b>{JSON.parse(message).text}</b></font>
        
        
      </div>
      
  ));

  return ( 
      <center> <br /><br /><br /> <br /><br /><br /> 
      
      <select className={styles.select} id="select" name="select">
            <option value="">Selecione APRS ou ISS</option>
            <option value="aprs">APRS PKT</option>
            <option value="iss">ISS PKT</option>
      </select>
      
      <button id="connect" className={styles.connect} onClick={connect}>Conectar</button> 
      <button id="disconnect" className={styles.disconnect} onClick={disconnect}>Desconectar {dataSource}</button> 
      <button id="seeMessages" className={styles.btnBlue} onClick={seeMessages}>Ver Mensagens <span className={styles.cardCounter}>{m}</span></button> 
      <button id="seeLocations" className={styles.btnBlue} onClick={seeLocations}>Ver Localizacao  <span className={styles.cardCounter}>{l}</span></button> 
      <button id="seeAll" className={styles.btnBlue} onClick={seeAll}>Ver Todos <span className={styles.cardCounter}>{l+m}</span></button> 
    
      <br /><br />

      {connected && messages.length > 0
        ? <div id="status" style={{display: "none"}}>&nbsp;</div>
        : <div id="status">Nao Conectado! Conecte para ver pacotes de localizacao e mensagens em tempo real.</div>
      }

      <div id="output" className={styles.output}>
          {messageList}
      </div>

      </center> 
    
  ); 

}; 

export default Realtime;