import React from "react"
import { useNavigate } from "react-router-dom";
import styles from './css/home.module.css'
const Home = (props) => {
    //console.log(props);

    const { loggedIn, user } = props
    const navigate = useNavigate();
    
    const onButtonClick = () => {
        if (loggedIn) {
            localStorage.removeItem("user");
            props.setLoggedIn(false)
        } else {
            navigate("/login")
        }
    }


    return <div className="mainContainer">
        <div className={"titleContainer"}>
            <div>Welcome!</div>
        </div>
        <div>
            Clique abaixo para fazer o login em APRSBR
        </div>
        <div className={"buttonContainer"}>
            <input
                className={styles.inputButton}
                type="button"
                onClick={onButtonClick}
                value={loggedIn ? "Sair" : "Entrar"} />
            {(loggedIn ? <div>
                Seu indicativo: {user}
            </div> : <div/>)}
        </div>


    </div>
}

export default Home