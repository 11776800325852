/* eslint-disable no-use-before-define */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Login = (props) => {
//console.log(props);
const [user, setUser] = useState('');
const [password, setPassword] = useState('');
const [loginOK, setLoginOK] = useState('');
//const [userError, setUserError] = useState('* Obrigatorio');
//const [passwordError, setPasswordError] = useState('* Obrigatorio - Mesma senha do APRS-IS');

const navigate = useNavigate()

//setUserError("");
//setPasswordError("");

function generateAPRSPassword(callsign) {
    let stophere = callsign.indexOf("-");
    if (stophere > 0) callsign = callsign.substr(0, stophere);
    let realcall = callsign.toUpperCase();
    while (realcall.length < 10) realcall += " ";
    let hash = 0x73e2;
    let i = 0;
    let len = realcall.length;
    while (i < len) {
        hash ^= realcall.charCodeAt(i) << 8;
        hash ^= realcall.charCodeAt(i + 1);
        i += 2;
    }
    return hash & 0x7fff;
    }

    const onButtonClick = () => {
        let validaPass = generateAPRSPassword(user);
        if (parseInt(password) === validaPass) {
            console.log(user, 'logado...');
            
            localStorage.setItem("user",JSON.stringify(user));
            //console.log('local' + localStorage.getItem("user"));
            
            props.setLoggedIn(true)
            props.setUser(user)
            setLoginOK(true)
            navigate('/bot');
            
        } else {
            console.log("password errada")
            setLoginOK('error')
        }
    
      }

  return (
    <div className={'mainContainer'}>
      <div className={'titleContainer'}>
        <div>Login</div>
      </div>
      <br />

      <div className={'inputContainer'}>
        <input
          value={user}
          placeholder="Indicativo..."
          onChange={(ev) => setUser(ev.target.value.toUpperCase())}
          className={'inputBox'}
        />

      </div>
      <br />

      <div className={'inputContainer'}>
        <input
          value={password}
          placeholder="Senha, a mesma do APRS-IS"
          onChange={(ev) => setPassword(ev.target.value)}
          className={'inputBox'}
        />
      </div>
      <br />

      <div className={'inputContainer'}>
        <input className={'inputButton'} type="button" onClick={onButtonClick} value={'Entrar'} />
      </div>
      <br />

      {loginOK === "error" &&
      
      <div style={{color: 'red', padding: "10px"}} >Senha ou indicativo incorreto...</div>
        
        }
    
    </div>
 
  )
}



export default Login