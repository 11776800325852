import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import styles from './css/bot.module.css'


//HTTPS
const socket = new WebSocket("wss://aprsbr.com.br:8089");

//HTTP
//const socket = new WebSocket("ws://localhost:8089/");

const Bot = (props) => {

  const { loggedIn, user } = props;
  const navigate = useNavigate();
  console.log('user',user);
  
  //const user = localStorage.getItem("user");
  const [messages, setMessages] = useState([]);
  //const [messageInput, setMessageInput] = useState('');
  const [isOnline, setIsOnline] = useState(false);
  
  const inputRef = useRef(); 

  const sendMessage = (e) => {
    e.preventDefault();
    //if (messageInput.trim() !== '') {
      if (inputRef.current.value.trim() !== '') {

      socket.send(JSON.stringify({
        user: user.replaceAll('"',""),
        //text: messageInput,
        text: inputRef.current.value,
        date: new Date().toLocaleTimeString(), //Sera alterada pelo servidor para garantir o mesmo timezone e formato entre os browsers
        type: "(E)", //Sera trocado no server pra (E) se for o owner da msg e para R par aos outros que irao receber
        select: document.getElementById("select").value
      }));
      inputRef.current.value = "";
      
      
    }
  };


  useEffect(() => {
    socket.onopen = () => {
      console.log("WebSocket Client Connected");
    };
    socket.onmessage = (event) => {
      const newMessages = [...messages, event.data];
      
      setMessages(newMessages);
    };

  }, [messages]);

  function go2endPage() {
    inputRef.current.focus(); //Manda o foco para o textbox da mensagem
    document.body.scrollIntoView(false); //faz o scroll pro fim da pagina
  }

  const disconnect = (e) => {
    e.preventDefault();
    
    
      socket.send(JSON.stringify({
        user: user.replaceAll('"',""),
        text: 'Desconectado!',
        date: new Date().toLocaleTimeString(), //Sera alterada pelo servidor para garantir o mesmo timezone e formato entre os browsers
        type: "(D)", //Sera trocado no server pra (E) se for o owner da msg e para R par aos outros que irao receber
        select: "chat"
      }));

      //setMessageInput('');

      navigate("/")
    
  };


  useEffect(() => {
    window.addEventListener('unload', () => {
      console.log('unload')
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (socket.readyState === WebSocket.OPEN) {
        setIsOnline(true);
        //socket.send(user);
        if (user!=="") {
          socket.send(JSON.stringify({
            user: user.replaceAll('"',""),
            text: "BEM-VINDO, ",
            date: new Date().toLocaleTimeString(),
            type: "(C)",
            select: "chat"
          }));
        }
      }
      if (socket.readyState === WebSocket.CLOSED) {
        setIsOnline(false);
      }
    }, 5);
  }, [user]);

  function goLogin() {
    navigate("/login");
  }

  /*
  //onOpen
  useEffect(() => {
    
    const socket = new WebSocket('wss://aprsbr.com.br:8089');
    socket.onopen = () => {
      console.log('WebSocket connection established.');
      setIsOnline(true);
    };
  }
});

  //onMessage
  useEffect(() => {
    socket.onmessage = (event) => {
      const newMessages = [...messages, event.data];
      setMessages(newMessages);
    };
  }, [messages]);

  //onClose
  useEffect(() => {
    return () => {
      socket.close();
    };
  }, []);

  //Welcome
  useEffect(() => {
    if (isOnline && !welcome) {
      socket.send(JSON.stringify({
        user: user.replaceAll('"',""),
        text: "BEM-VINDO, ",
        date: new Date().toLocaleTimeString(),
        type: "(C)",
        select: "chat"
      }));
      setWelcome(true)
    }
  }, [isOnline, welcome, user, setWelcome]);

  function go2endPage() {
    inputRef.current.focus(); //Manda o foco para o textbox da mensagem
    document.body.scrollIntoView(false); //faz o scroll pro fim da pagina
  }

  const disconnect = (e) => {
    e.preventDefault();
    
    
      socket.send(JSON.stringify({
        user: user.replaceAll('"',""),
        text: 'Desconectado!',
        date: new Date().toLocaleTimeString(), //Sera alterada pelo servidor para garantir o mesmo timezone e formato entre os browsers
        type: "(D)", //Sera trocado no server pra (E) se for o owner da msg e para R par aos outros que irao receber
        select: "chat"
      }));

      setMessageInput('');

      navigate("/")
    
  };


//TODO se ambos fores false... colocar um div pra recarregar a pagina apenas.
console.log('Logado:', loggedIn)
console.log('Server On:', isOnline)
*/

    return (
      <center>

        {
        //!user 
        !loggedIn
        ?   <>
            <br /><br /><br /><br /><br /><br /><div className={styles.titleContainer}>Usuário não logado!</div>
            <div className={"buttonContainer"}>
            <input
                className={styles.inputButton}
                type="button"
                onClick={goLogin}
                value="Login" />
            
        </div>

            
            </>
        : <>
        <br /><br /><br /><br />
        <div>
          {(!loggedIn || !isOnline)
          && <><br /><br /><div className={styles.btnReload}>Conectando...</div><br /><br /></>
          }
        </div>
        <div className={styles.containerMessages}>
          <div>
            {messages.map((message, index) => {
            
              let msg = JSON.parse(message);
             //console.log(msg)
              switch (msg.type) {
                case '(E)':
                  go2endPage();
                  return (
                    <div key={index}>
                    <div className={styles.enviada}><b><i>{msg.user}</i></b>{msg.text}&nbsp;&nbsp;&nbsp;<i className="fa-solid fa-paper-plane"></i></div>
                    <br />
                    <br />
                    <div className={styles.spaceR}>{msg.date}&nbsp;<i className="fa-solid fa-check-double"></i></div>
                    </div>
                    
                  )
                
                case '(R)':
                  go2endPage();
                  return (
                    <div key={index}>
                    <div className={styles.recebida}><b><i>{msg.user}</i></b>{msg.text}&nbsp;&nbsp;&nbsp;<i className="fa-solid fa-comment-dots"></i></div>
                    <br />
                    <br />
                    <div className={styles.spaceL}>{msg.date}&nbsp;<i className="fa-solid fa-check-double"></i></div>
                    </div>
                  )
                
                case '(D)':
                  go2endPage();
                  return (
                    <div key={index}>
                    <div className={styles.disconnect}><b><i>{msg.user}</i></b>{msg.text}&nbsp;&nbsp;&nbsp;<i className="fa-solid fa-user-slash"></i></div>
                    <br />
                    <br />
                    <div className={styles.spaceL}>{msg.date}&nbsp;<i className="fa-solid fa-check-double"></i></div>
                    </div>
                  )
                
                case '(C)':
                  go2endPage();
                  return (
                    <div key={index}>
                      <br />
                      <div className={styles.centered} key={index}>{msg.text + msg.user.replace(">","") + "!"}</div>
                      <br />
                      <div>{msg.usersConnected}</div>
                    </div>
                  )
                default:
                  return <div></div>
  
              }}
            )}
            
          </div>
            
                
              
          <br />
          <div>

          <select className={styles.select} id="select" name="select">
            <option value="chat">CHAT</option>
            <option value="bot">BOT</option>
          </select>
          &nbsp;&nbsp;
            <input
              ref={inputRef} //dar o foco no botao sempre
              id="message"
              className={styles.text}
              type="text"
            />
            &nbsp;&nbsp;<button className={styles.btn} onClick={sendMessage}>ENVIAR</button>
            <br /><br /><button className={styles.btnLogout} onClick={disconnect}>SAIR</button>
            <br /><br /><br /><br /><br />
          </div>
          


        </div>
        </>
        }
        </center>
      );
    };

export default Bot



