import styles from './css/menu.module.css'
import { Link, useLocation } from 'react-router-dom'

import iconMenu from './images/iconMenu.svg'

function Menu(props) {
    const { loggedIn, user } = props;
    let displayNone = styles.displayNone;

    function openMenu() {
        document.getElementById("iconMenu").classList.add(displayNone);
        document.getElementById("menu").classList.remove(displayNone);
    }

    function closeMenu() {
        document.getElementById("iconMenu").classList.remove(displayNone);
        document.getElementById("menu").classList.add(displayNone);
    }

    let location = useLocation().pathname;

    return (
        <div>
        <div className={styles.iconMenu} id='iconMenu'>
            <img src={iconMenu} alt="" onClick={openMenu} />
        </div>
        <div id="menu" className={styles.menuContainer + ' ' + styles.displayNone}>
            <p className={styles.close} onClick={closeMenu}>x</p>
            <Link className={location === '/' ? styles.linkActive : styles.link} onClick={closeMenu} to="/">{location === '/' ? <label>HOME</label> : "HOME"}</Link>
            {(!loggedIn || user === "")
            && <Link className={location === '/login' ? styles.linkActive : styles.link} onClick={closeMenu} to="/login">{location === '/login' ? <label>LOGIN</label> : "LOGIN"}</Link>
            }
            <Link className={location === '/bot' ? styles.linkActive : styles.link} onClick={closeMenu} to="/bot">{location === '/bot' ? <label>BOT</label> : "BOT"}</Link>
            <Link className={location === '/realtime' ? styles.linkActive : styles.link} onClick={closeMenu} to="/realtime">{location === '/realtime' ? <label>REALTIME</label> : "REALTIME"}</Link>
            <Link className={location === '/aprstt' ? styles.linkActive : styles.link} onClick={closeMenu} to="/aprstt">{location === '/aprstt' ? <label>APRSTT</label> : "APRSTT"}</Link>
            <Link className={location === '/iss' ? styles.linkActive : styles.link} onClick={closeMenu} to="/iss">{location === '/iss' ? <label>ISS</label> : "ISS"}</Link>
            <Link className={location === '/digis' ? styles.linkActive : styles.link} onClick={closeMenu} to="/digis">{location === '/digis' ? <label>DIGIS</label> : "DIGIS"}</Link>
            <Link className={location === '/tracker' ? styles.linkActive : styles.link} onClick={closeMenu} to="/tracker">{location === '/tracker' ? <label>TRACKER</label> : "TRACKER"}</Link>
            <Link className={location === '/webserial' ? styles.linkActive : styles.link} onClick={closeMenu} to="/webserial">{location === '/eventos' ? <label>WEBSERIAL</label> : "WEBSERIAL"}</Link>
            <Link className={location === '/ajuda' ? styles.linkActive : styles.link} onClick={closeMenu} to="/ajuda">{location === '/ajuda' ? <label>AJUDA</label> : "AJUDA"}</Link>
        </div>
    </div>

    )
}

export default Menu