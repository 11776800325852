import React, { useState, useEffect } from 'react';
import styles from './css/iss.module.css'
import "leaflet/dist/leaflet3.css"
import { NightRegion } from "./night-region/night-region.js"
//https://api.wheretheiss.at/v1/satellites/25544
import useInterval from "use-interval";
import {Tooltip, MapContainer, TileLayer, Marker, ZoomControl, Popup, Circle, useMapEvent, Polyline,  } from "react-leaflet";
import { Icon } from "leaflet";
import { getGroundTracks } from "tle.js"


const Iss = (props) => {


const [issLat, setIssLat] = useState(0);
const [issLong, setIssLong] = useState(0);
const [issVel, setIssVel] = useState(0);
const [issAlt, setIssAlt] = useState(0);
const [solarLat, setSolarLat] = useState(0);
const [solarLong, setSolarLong] = useState(0);
const [visibility, setVisibility] = useState(null);

const [myLat, setMyLat] = useState(0);
const [myLon, setMyLon] = useState(0);

const [issDistance, setIssDistance] = useState(0);

const [latLngs, setLatLngs] = useState([]);
const [latLngs2, setLatLngs2] = useState([]);
const [latLngs3, setLatLngs3] = useState([]);
const [bearing, setBearing] = useState(0);
const [mid, setMid] = useState([]);

let polylinePoints = [[]];

//https://www.amsat.org/tle/current/nasabare.txt
//https://celestrak.org/NORAD/elements/gp.php?GROUP=active&FORMAT=tle/25544
//tle
const tleStr = `ISS (ZARYA)             
1 25544U 98067A   24127.82853009  .00015698  00000-0  27310-3 0  9996
2 25544  51.6393 160.4574 0003580 140.6673 205.7250 15.50957674452123`;

/*
fetch('https://celestrak.org/NORAD/elements/gp.php?GROUP=active&FORMAT=tle/25544')
.then(response => response.text())
.then(data => {
    const lines = data.toString().split('ISS');
    //const issLines = lines.slice(7, 9);
    console.log(lines[1]);
  })
.catch(error => console.error('Error fetching data:', error));
*/

function distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit==="K") { dist = dist * 1.609344 } //KM 
        if (unit==="N") { dist = dist * 0.8684 } //Milhas nauticas
        return dist;
    }
}

async function showPosition(position) {  
    setMyLat(position.coords.latitude);
    setMyLon(position.coords.longitude);
}

function getLocation() {
    console.log("pegando geolocation...")
if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
} else {
    document.getElementById("codigoDTMF").value = "Geolocation is not supported by this browser.";
}
}


useEffect(() => {
    if(myLat===0 || myLon === 0) {
    getLocation();
    }
    });


  let orbitalDataUrl = "https://api.wheretheiss.at/v1/satellites/25544";

  const mapBounds = [
    [-90, -180],
    [90, 180],
];

  const issIcon = new Icon({
    iconUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/International_Space_Station_%28Expedition_58_Patch%29.svg/500px-International_Space_Station_%28Expedition_58_Patch%29.svg.png",
    iconSize: [70, 50],
  });

  const sunIcon = new Icon({
    iconUrl: require("./images/sun2.png"),
    iconSize: [60, 60],
  });

  const myPosition = new Icon({
    iconUrl: require("./images/positionIcon.png"),
    iconSize: [60, 60],
  });

  const midPoint = ([x1, y1], [x2, y2]) => [(x1 + x2) / 2, (y1 + y2) / 2];



  // Converts from degrees to radians.
function toRadians(degrees) {
    return degrees * Math.PI / 180;
  };
   
  // Converts from radians to degrees.
  function toDegrees(radians) {
    return radians * 180 / Math.PI;
  }
  
  
  function getBearing(startLat, startLng, destLat, destLng){
    startLat = toRadians(startLat);
    startLng = toRadians(startLng);
    destLat = toRadians(destLat);
    destLng = toRadians(destLng);
  
    let y = Math.sin(destLng - startLng) * Math.cos(destLat);
    let x = Math.cos(startLat) * Math.sin(destLat) -
          Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = toDegrees(brng);
    return (brng + 360) % 360;
  }


  useInterval(async () => {
    const response = await fetch(orbitalDataUrl);
    const data = await response.json();
    const { latitude, longitude, velocity, altitude, solar_lat, solar_lon, visibility } = data;
    // console.log(altitude);
    
    // Set new state values
    setIssLat(latitude);
    setIssLong(longitude);
    setIssVel(velocity);
    setIssAlt(altitude);
    setVisibility(visibility);
    setSolarLat(solar_lat);
    setIssDistance(distance(myLat, myLon,latitude,longitude,"K"));
    setMid(midPoint([myLat,myLon],[issLat,issLong]));

    //console.log(mid_point([myLat,myLon],[issLat,issLong]))
    //console.log(latLngs3)
    console.log(latLngs3.length)
    console.log(myLat, myLon,issLat, issLong);
    
    console.log('bearing',getBearing(myLat, myLon,latitude, longitude));

    setBearing(getBearing(myLat, myLon,issLat, issLong));

    
    polylinePoints = [
        [myLat, myLon],
        [latitude, longitude]
    ]

    setLatLngs3(polylinePoints);

    getGroundTracks({
        tle: tleStr,
        startTimeMS: Date.now(),
        stepMS: 5000,
        isLngLatFormat: false,
    }).then(function (threeOrbitsArr) {
        setLatLngs(threeOrbitsArr[1]) //[0] last passage //[1] current passage //[2] next passage
        setLatLngs2(threeOrbitsArr[2]) //[0] last passage //[1] current passage //[2] next passage
        
    });
    // Conditional statement to account for different scales on ISS and solar longitude values
    if (solar_lon > 180) {
        setSolarLong(solar_lon - 360);
    } else {
        setSolarLong(solar_lon);
    }
}, 5000);

    // Function to recentre map on click
    const FindIss = () => {
        const map = useMapEvent("click", () => {
            map.flyTo([issLat, issLong], 3);
        });
        return null;
    };

   
  return (
    <>
    <br /><br /><br />
    
    <div className={styles.infobox} id='infobox'>
        <pre>ISS Telemetry<br />
        <font color="#4CFF00">
        Lat : {parseFloat(issLat).toFixed(4)}<br />
        Lon : {parseFloat(issLong).toFixed(4)}<br />
        Vel : {parseInt(issVel)} km/h<br />
        Alt : {parseInt(issAlt)} km<br />
        Vis : {visibility}<br />
        Dist: {issDistance.toFixed(0)} Km<br />
        Deg : {bearing.toFixed(1)}&deg;<br />
        </font>
        <span style={{color: "red"}}>- Passagem Atual</span><br />
        <span style={{color: "yellow"}}>- Prox. Passagem</span><br />

        UPLI: 145.990 (67)<br />
        DOWN: 437.800<br />
        APRS: 145.825

        </pre>
    </div>
    
    <MapContainer 
        bounds={mapBounds}
        center={[issLat, issLong]}
        zoom={2}
        style={{ height: "100vh" }}
        scrollWheelZoom={true}
        worldCopyJump={true}
        maxBoundsViscosity={1}
        zoomControl={false}
    >
      
    <TileLayer
        //attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
        //url="https://api.maptiler.com/maps/outdoor/{z}/{x}/{y}.png?key=I5jJIO0gVFZgkPhGgi1t"
        attribution='&copy; <a href="https://www.esri.com/">Esri - Maplink Wholink</a>'
       
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        //MAps => World_Imagery, NatGeo_World_Map, World_Physical_Map, World_Shaded_Relief, World_Street_Map, World_Terrain_Base, World_Topo_Map
    />
    
    <ZoomControl position="topleft" />
    
    <FindIss />

    <NightRegion fillColor="#00" fillOpacity="0.4" color="#00" worldCopyJump={true} />

    <Polyline positions={latLngs} weight={1} color={"red"} />
    <Polyline positions={latLngs2} weight={1} color={"yellow"} dashArray={'20, 20'} />
    <Polyline positions={latLngs3} weight={0.8} color={"white"} />
    
    <Marker position={[issLat, issLong]} icon={issIcon}>
        <Popup>
            <p>
                <b>Posição da ISS</b><br />
                Velocidade: {parseFloat(issVel).toFixed(2)} km/h <br />
                Altitude: {parseFloat(issAlt).toFixed(2)} Km <br />
                Visibilidade: {visibility} <br />
            </p>
        </Popup>
   </Marker>

    <Marker position={[solarLat, solarLong]} icon={sunIcon}>
        <Popup>
            <p>Posição do SOL</p>
        </Popup>
    </Marker>

    <Marker position={[myLat, myLon]} icon={myPosition}>
        <Popup>
            <p>Minha Posicao</p>
        </Popup>
    </Marker>
    
    {latLngs3.length === 2 &&
        <Circle center={mid} radius={1} color={"red"} opacity={0.1}>
            <center>
            <Tooltip direction="bottom" opacity={0.8} permanent>
                <center><div>{issDistance.toFixed(0)} Km<br />{bearing.toFixed(1)}&deg;</div></center>
                
            </Tooltip>
            </center>
        </Circle>
    }
    
    <Circle center={[myLat, myLon]} radius={1000000} color={"red"} opacity={0.5} title={"Meu horizonte"} />
    <Circle center={[issLat, issLong]} radius={1100000} color={"#00DD00"} fillColor={"#00DD00"} opacity={0.4} title={"ISS horizonte visivel"} />

    </MapContainer>
    
    </>
  );
}

export default Iss