import React from "react"
import styles from './css/ajuda.module.css'

const Ajuda = (props) => {

    return (
        <center>
        <div>
        <br /><br /><br /><br /> <br />
        <table width='80%'>
          <thead>
          <tr>
            <th width='20%'>Para</th>
            <th width='40%'>Mensagem</th>
            <th width='40%'>Retorno</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td className={styles.grayd0}>APRSBR</td>
            <td className={styles.grayd0}>wx [Indicativo Estacao Meteorologica]</td>
            <td className={styles.grayd0}>Retorna dados da estacao APRS WX<br />Exemplo: wx PU2ONU-13</td>
          </tr>

          <tr>
            <td className={styles.grayf8}>APRSBR</td>
            <td className={styles.grayf8}>cep [numero]</td>
            <td className={styles.grayf8}>Retorna os dados do CEP<br />Exemplo: cep 01311200 ou cep 01311-200</td>
          </tr>
          <tr>
            <td className={styles.grayd0}>APRSBR</td>
            <td className={styles.grayd0}>metar [ICAO]</td>
            <td className={styles.grayd0}>Envia os dados meteorologicos METAR.<br />Ex: metar SBCG</td>
          </tr>

          <tr>
            <td className={styles.grayf8}>APRSBR</td>
            <td className={styles.grayf8}>getpos [INDICATIVO] ou apenas getpos</td>
            <td className={styles.grayf8}>Envia a ultima posicao LAT/LON do indicativo.<br />Ex: getpos PU2XXX-15</td>
          </tr>


          <tr>
            <td className={styles.grayd0}>APRSBR</td>
            <td className={styles.grayd0}>clima [cidade] ou apenas clima</td>
            <td className={styles.grayd0}>Retorna os dados meteorologicos da cidade<br />Exemplo: clima guarulhos<br />Se enviar apenas clima retorna os dados da sua ultima posicao</td>
          </tr>
          <tr>
            <td className={styles.grayf8}>APRSBR</td>
            <td className={styles.grayf8}>aprsd [indicativo]</td>
            <td className={styles.grayf8}>Retorna Estações ouvidas em direto pelo indicativo informado.<br />Exemplo: aprsd PU2ONU-15</td>
          </tr>
          <tr>
            <td className={styles.grayd0}>APRSBR</td>
            <td className={styles.grayd0}>qrz [indicativo]</td>
            <td className={styles.grayd0}>Retorna nome e endereço do indicativo informado<br />Exemplo: qrz PU2ONU</td>
          </tr>

          <tr>
            <td className={styles.grayf8}>APRSBR</td>
            <td className={styles.grayf8}>aprst</td>
            <td className={styles.grayf8}>Retorna o path do pacote ate APRSBR</td>
          </tr>
          <tr>
            <td className={styles.grayd0}>APRSBR</td>
            <td className={styles.grayd0}>setpos [lat] [lon] [comentario]<br />Obs: O comentario nao pode ter espaços se precisar separar use _ Ex: Boa_Noite Lat Long no formato do exemplo.</td>
            <td className={styles.grayd0}>Plota a estação que enviou a mensagem com as lat lon no aprs.fi<br />Exemplo: setpos 2240.00S 04658.00W Comentario</td>
          </tr>
          <tr>
            <td className={styles.grayf8}>APRSBR</td>
            <td className={styles.grayf8}>echo [mensagem]</td>
            <td className={styles.grayf8}>Retorna um echotest e o path da mensagem.<br />Exemplo: echo Ola testando</td>
          </tr>
          <tr>
            <td className={styles.grayd0}>APRSBR</td>
            <td className={styles.grayd0}>ajuda</td>
            <td className={styles.grayd0}>Retorna a URL desse website com os comandos.</td>
          </tr>
          <tr>
            <td className={styles.grayf8}>APRSBR</td>
            <td className={styles.grayf8}>gadd</td>
            <td className={styles.grayf8}>Adiciona o indicativo que mandou a mensagem ao grupo</td>
          </tr>
          <tr>
            <td className={styles.grayd0}>APRSBR</td>
            <td className={styles.grayd0}>glist</td>
            <td className={styles.grayd0}>Retorna os indicativos que estão no grupo</td>
          </tr>
          <tr>
            <td className={styles.grayf8}>APRSBR</td>
            <td className={styles.grayf8}>gdel</td>
            <td className={styles.grayf8}>Remove o usuário que enviou a mensagem do grupo</td>
          </tr>
          <tr>
            <td className={styles.grayd0}>APRSBR</td>
            <td className={styles.grayd0}>gmsg [mensagem]</td>
            <td className={styles.grayd0}>Envia a mensagem para mailbox de todos os indicativos que estão no grupo.<br />Para consultar os indicativos basta enviar: glist</td>
          </tr>
          <tr>
            <td className={styles.grayf8}>APRSBR</td>
            <td className={styles.grayf8}>mbc</td>
            <td className={styles.grayf8}>Checa se tem alguma mensagem para voce na mail box.<br />Ex: mbc</td>
          </tr>
          <tr>
            <td className={styles.grayd0}>APRSBR</td>
            <td className={styles.grayd0}>mbr</td>
            <td className={styles.grayd0}>Recebe todas suas msg da mail box.<br />Ex:mbr</td>
          </tr>
          <tr>
            <td className={styles.grayf8}>APRSBR</td>
            <td className={styles.grayf8}>mbd</td>
            <td className={styles.grayf8}>Deleta todas as mensagens da sua mail box.<br />Ex: mbd</td>
          </tr>
          </tbody>
        </table>



            </div>
            </center>
    )
}

export default Ajuda