import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './home'
import Login from './login'
import Bot from './bot'
import Realtime from './realtime'
import Menu from './menu'
import Aprstt from './aprstt'
import Ajuda from './ajuda'
import Iss from './iss'
import Digis from './digis'
import Tracker from './tracker'
import Webserial from './webserial'
import './App.css'
import { useEffect, useState } from 'react'

function App() {

  const [loggedIn, setLoggedIn] = useState(false)
  const [user, setUser] = useState('')
  
  

  useEffect(() => {
    // Fetch the user email and token from local storage
    //////const user = JSON.parse(localStorage.getItem('user'))
    if (localStorage.getItem("user")) {
      //const user = localStorage.getItem("user").replaceAll('"','');
      setUser(localStorage.getItem("user").replaceAll('"',''))
    }
    //const user = localStorage.getItem("user");

  
    // If the user does not exist, mark the user as logged out
    if (!user) {
      setLoggedIn(false);
      return
    }
  
    setLoggedIn(true);
    setUser(user);
    

    
    
  }, [user])
  
  return (
    <div className="App">
      <BrowserRouter>
      <Menu loggedIn={loggedIn} user={user}/>
        <Routes>
          <Route path="/" element={<Home user={user} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />} />
          <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setUser={setUser} />} />
          <Route path="/bot" element={<Bot loggedIn={loggedIn} user={user} setLoggedIn={setLoggedIn} setUser={setUser} />} />
          <Route path="/aprstt" element={<Aprstt loggedIn={loggedIn} user={user} setLoggedIn={setLoggedIn} setUser={setUser} />} />
          <Route path="/ajuda" element={<Ajuda loggedIn={loggedIn} user={user} setLoggedIn={setLoggedIn} setUser={setUser} />} />
          <Route path="/iss" element={<Iss loggedIn={loggedIn} user={user} setLoggedIn={setLoggedIn} setUser={setUser} />} />
          <Route path="/digis" element={<Digis loggedIn={loggedIn} user={user} setLoggedIn={setLoggedIn} setUser={setUser} />} />
          <Route path="/tracker" element={<Tracker loggedIn={loggedIn} user={user} setLoggedIn={setLoggedIn} setUser={setUser} />} />
          <Route path="/webserial" element={<Webserial loggedIn={loggedIn} user={user} setLoggedIn={setLoggedIn} setUser={setUser} />} />
          <Route path="/realtime" element={<Realtime loggedIn={loggedIn} user={user} setLoggedIn={setLoggedIn} setUser={setUser} />} />
         </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App